<template>
  <div class="clothes-main">
    <div>
      <div class="title">衣服信息</div>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="details.clothesNo"
            name="clothesNo"
            label="洗衣编号"
            readonly 
          />
          <van-field
            v-model="details.username"
            name="username"
            label="姓名"
            readonly 
          />
          <van-field
            v-model="details.remark"
            name="remark"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="100"
            placeholder="请输入备注信息"
            show-word-limit
          />
          <div style="color:red;padding-top:10px;">请选择 “从相册选择” 上传照片，部分手机拍照上传暂不支持</div>
          <van-field name="uploader" label="照片上传">
            <template #input>
              <van-uploader v-model="fileList" :after-read="afterRead" multiple />
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group>
          <van-cell v-for="item in details.stan" :key="item.id" :title="item.clothesNo+' '+item.cateName" :value="item.clothesName+' '+item.serverName+' '+item.color" />
        </van-cell-group>
        <div class="sub-btn">
          <div class="s-b-i"><van-button round block type="primary" native-type="submit" @click="scanContinuous(0)">提交保存</van-button></div>
          <div class="s-b-i"><van-button round block type="primary" native-type="submit" @click="scanContinuous(1)">提交保存 -》连续扫码上传</van-button></div>
        </div>
      </van-form>

    </div>
    <WxNoShare />
  </div>
</template>

<script>
  import { ref, getCurrentInstance, onMounted } from 'vue';
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router';
  import { Grid, GridItem, Popup, Form, Field, Cell, CellGroup, Uploader, Toast } from 'vant';
  import wx from 'weixin-jsapi';
  import axios from 'axios';
  //import Vconsole from 'vconsole'; //调试
  import Compressor from "compressorjs";

  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [Popup.name]: Popup,
      [Form.name]: Form,
      [Field.name]: Field,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Uploader.name]: Uploader,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const route = useRoute()
      const userInfo = ref(store.state);
      const details = ref({});
      const fileList = ref([]);
      const thumbnail = ref([]);
      const continuous = ref(0);
      //const vconsole = new Vconsole();vconsole;
      
      //获取衣服信息
      function getDetails() {
        fileList.value = [];
        proxy.$api.get('laundrysStans/show', {no: route.query.no, type: route.query.type}, r=>{
          details.value = r.result;
          r.result.thumbnail.map((item) =>{
            fileList.value.push({url: item});
          })
        })
      }
      getDetails();

      onMounted(() => {
        getWechatConfig();
      });

      function scanContinuous(type){
        continuous.value = type;
      }

      //获取微信参数
      function getWechatConfig() {
        let url = encodeURIComponent(location.href);
        axios.get(process.env.VUE_APP_API_URL + 'settings/wechatConfig?sid='+localStorage.sid+'&url='+url).then(function (res) {
            let data = res.data
            wx.config(data.data)
            wx.ready(function() {
              /*
              let shareData = {
                title: self.details.share_title,
                desc: self.details.share_brief,
                link: self.shareLink,
                imgUrl: self.details.image,
              }
              */
              //wx.showOptionMenu();
              wx.hideOptionMenu();
              //wx.onMenuShareAppMessage(shareData)
              //wx.onMenuShareTimeline(shareData)
            });
        }).catch(function (error) {
            console.log(error);
        });
      }
      
      //开始扫码
      function scan(){
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                route.push({name: 'laundrysClothes', query: {no: result, type: 3}})
            }
        });
      }


      //保存更新
      function onSubmit() {
        thumbnail.value = [];
        fileList.value.map((item) =>{
          thumbnail.value.push(item.url);
        })
        proxy.$api.post('laundrys/updateOther', {id: details.value.id, remark: details.value.remark, thumbnail: thumbnail.value}, r=>{
          if(r.code == 200){
            Toast('保存成功')
            getDetails();
            if(continuous.value == 1){
              scan();
            }
          }
        })
      }

      //上传图片
      function afterRead(file) {
        //console.log('file', file);
        if(file.length > 0){
          //多张上传
          file.map(async (f) =>{
            f.status = 'uploading';
            f.message = '上传中...';
            new Compressor(f.file, {
              quality: 0.6,
              success(result) {
                const formData = new FormData();
                formData.append('file', result, result.name);
                //接口调用
                proxy.$api.post(process.env.VUE_APP_API_URL + 'upload', formData, r=>{
                  f.status = 'done';
                  f.message = '上传成功';
                  f.url = r.result.url;
                })
              },
              error(err) {
                console.log(err.message);
              },
            });

          })
        }else{
          //单张上传
          file.status = 'uploading';
          file.message = '上传中...';
          new Compressor(file.file, {
            quality: 0.6,
            success(result) {
              const formData = new FormData();
              formData.append('file', result, result.name);
              //接口调用
              proxy.$api.post(process.env.VUE_APP_API_URL + 'upload', formData, r=>{
                file.status = 'done';
                file.message = '上传成功';
                file.url = r.result.url;
              })
            },
            error(err) {
              console.log(err.message);
            },
          });
        }
      }

      //上传前对图片压缩处理
      function beforeRead(file) {
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            // 压缩质量，0-1之间。数字越小，压缩比越高
            quality: 0.2,
            success(result) {
              // 默认返回result是blob类型的文件，可以转成file并返回，交给afterRead钩子进行上传
              let newFile = new File([result], file.name, { type: file.type })
              resolve(newFile)
            },
            error(err) {
              reject(err)
            },
          })
        })
      }

      return {
        userInfo,
        details,
        fileList,
        afterRead,
        beforeRead,
        onSubmit,
        scanContinuous
      };
    },
  };
</script>
<style scoped>
  .clothes-main {
    padding-bottom: 10px;
    background-color: #fff;
  }
  .title{
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #e1dddd;
    padding:10px;
  }
  .sub-btn{
    padding:10px;
    overflow: hidden;
  }
  .sub-btn .s-b-i{
    margin-bottom: 10px;
  }
</style>
