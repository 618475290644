<template>
  <div>
    <van-tabs v-model:active="active" @change="onChange">
        <van-tab :title="`扫码` + shopTitle"></van-tab>
        <van-tab :title="shopTitle + `记录`"></van-tab>
    </van-tabs>
    <template v-if="flow == 1">
      <div class="statistics">
        扫码列表只显示当天{{ shopTitle }}衣服，今日共扫码{{ shopTitle }}<span>{{ statistics.shop.dayCount }}</span>件
      </div>
      <div class="scan-btn">
        <van-button type="primary" size="small" @click="scan">开始扫码</van-button>
        <van-button type="primary" size="small" @click="manual" class="manual">手动{{ shopTitle }}</van-button>
      </div>
    </template>
    <template v-else>
      <div class="statistics">
        {{ shopTitle }}记录全部衣服，{{ shopTitle }}总衣服量<span>{{ statistics.shop.sumCount }}</span>件
      </div>
      <van-search
        v-model="clothesNo"
        show-action
        label="编号"
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onClickButton">搜索</div>
        </template>
      </van-search>
    </template>
    <div class="shop-main">
      <van-row class="th">
        <van-col span="6">洗衣编号</van-col>
        <van-col span="8">衣服</van-col>
        <van-col span="10">{{ shopTitle }}日期</van-col>
      </van-row>
      <van-row class="tr" v-for="item in shopList" :key="item.id">
        <van-col span="6">{{ item.clothesNo }}</van-col>
        <van-col span="8">{{ item.clothesName }}</van-col>
        <van-col span="10">{{ item.obtainDate }}</van-col>
      </van-row>
    </div>
    <van-pagination v-if="totalItems > 0" v-model="currentPage" :total-items="totalItems" :items-per-page="perPage" @change="changePage" force-ellipses />
    <van-popup v-model:show="manualShow" closeable>
      <div class="popup-manual">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="manualClothesNo"
              name="manualClothesNo"
              label="洗衣编号"
              placeholder="请填写洗衣编号"
              :rules="[{ required: true, message: '请填写洗衣编号' }]"
            />
          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <Footer />
  </div>
</template>

<script>
  import { ref, getCurrentInstance, onMounted } from 'vue';
  import { Tab, Tabs, List, Pagination, Search, Popup, Form, Field, CellGroup } from 'vant';
  import axios from 'axios';
  import { useStore } from 'vuex';
  import wx from 'weixin-jsapi';

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [List.name]: List,
      [Pagination.name]: Pagination,
      [Search.name]: Search,
      [Popup.name]: Popup,
      [Form.name]: Form,
      [Field.name]: Field,
      [CellGroup.name]: CellGroup,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const userInfo = ref(store.state);
      const statistics = ref({ shop: {dayCount: 0, sumCount: 0} });
      const active = ref(0);
      const shopList = ref([]);
      const currentPage = ref(1);
      const totalItems = ref(0);
      const perPage = ref(5);
      const status = ref(3);
      const flow = ref(1);
      const clothesNo = ref('');
      const shopTitle = ref('');
      const manualClothesNo = ref('');
      const manualShow = ref(false);

      onMounted(() => {
        getStatistics();
        onLoad();
        getWechatConfig();
        shopTitle.value = userInfo.value.storeType == 1 ? '入店' : '上挂';
      });

      function onSearch(val) {
        clothesNo.value = val;
      }

      function onClickButton() {
        onLoad();
      }

      //获取微信参数
      function getWechatConfig() {
        let url = encodeURIComponent(location.href);
        axios.get(process.env.VUE_APP_API_URL + 'settings/wechatConfig?type=1&sid='+userInfo.value.sid+'&url='+url).then(function (res) {
            let data = res.data
            wx.config(data.data)
            wx.ready(function() {
              /*
              let shareData = {
                title: self.details.share_title,
                desc: self.details.share_brief,
                link: self.shareLink,
                imgUrl: self.details.image,
              }
              */
              wx.showOptionMenu();
              //wx.onMenuShareAppMessage(shareData)
              //wx.onMenuShareTimeline(shareData)
            });
        }).catch(function (error) {
            console.log(error);
        });
      }

      //开始扫码
      function scan(){
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                // eslint-disable-next-line no-unused-vars
                proxy.$api.post('laundrysStans/update', {clothesNo: result, type: 3}, r=>{
                  getStatistics();
                  onLoad();
                })
            }
        });
      }

      //手动入店|上挂
      function manual(){
        manualClothesNo.value = '';
        manualShow.value = true;
      }

      const onSubmit = (values) => {
        // eslint-disable-next-line no-unused-vars
        proxy.$api.post('laundrysStans/update', {clothesNo: values.manualClothesNo, type: 3}, r=>{
          getStatistics();
          onLoad();
          manualShow.value = false;
        })
      };

      //统计数据
      function getStatistics() {
        proxy.$api.get('laundrysStans/data', {}, r=>{
          statistics.value = r.result;
        })
      }

      //加载数据
      function onLoad() {
        proxy.$api.get('laundrysStans/index', {page: currentPage.value, pageSize: 15, status: status.value, flow: flow.value, clothesNo: clothesNo.value}, r=>{
          let res = r.result;
          shopList.value = res.items;
          currentPage.value = res.currentPage;
          totalItems.value = res.total;
          perPage.value = res.perPage;
        })
      }
      

      //tab切换
      function onChange(e) {
        active.value = e;
        flow.value = active.value == 0 ? 1 : 0;
        shopList.value = [];
        onLoad();
      }

      //翻页
      function changePage(e) {
        currentPage.value = e;
        onLoad();
      }

      return {
        active,
        statistics,
        onChange,
        shopList,
        currentPage,
        totalItems,
        perPage,
        changePage,
        scan,
        flow,
        clothesNo,
        onSearch,
        onClickButton,
        shopTitle,
        manualShow,
        manual,
        manualClothesNo,
        onSubmit,
      };
    },
  };
</script>
<style scoped>
  .statistics {
    overflow: hidden;
    font-size: 13px;
    text-align: center;
    padding: 10px 0;
    background-color: #e6f7ff;
  }
  .statistics span {
    font-weight: bold;
    display: inline-block;
    margin: 0 5px;
    color: #1989fa;
  }
  .scan-btn {
    padding: 10px 15px;
    background-color: #fff;
  }
  .shop-main {
    padding: 0 0 10px;
    overflow: hidden;
    background-color: #fff;
  }
  .shop-main .th {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #f5f6f7;
    line-height: 30px;
  }
  .shop-main .tr {
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    border-bottom: 1px solid #f5f6f7;
  }
  .shop-main .tr:last-child {
    border-bottom: none;
  }
  .shop-main .tr:nth-child(2n-1) {
    background: #fafafa;
  }
  .manual {
    margin-left: 15px;
  }
  .popup-manual {
    border-radius: 3px;
    width: 300px;
    padding: 15px 0;
  }
</style>
