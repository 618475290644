<template>
  <div>
    <div class="manage">
      <van-grid square @click="onIcon">
        <van-grid-item key="2" icon="qr" text="门店二维码" @click="showPopupQr" />
        <van-grid-item key="11" icon="newspaper-o" text="上传照片" @click="toUrl('shopPhoto')" />
        <van-grid-item key="1" icon="label-o" text="推广海报" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="3" icon="shop-o" text="门店设置" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="10" icon="logistics" text="洗衣管理" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="4" icon="friends-o" text="会员管理" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="5" icon="hot-o" text="营销管理" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="6" icon="description" text="订单管理" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="7" icon="chart-trending-o" text="业绩统计" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="8" icon="after-sale" text="账务管理" icon-color="#b5b2b2" class="i-color" />
        <van-grid-item key="9" icon="newspaper-o" text="教育培训" icon-color="#b5b2b2" class="i-color" />
      </van-grid>
    </div>
    <van-popup v-model:show="rqCodeShow">
      <img :src="`https://api.woxiyi.cn/qrcode/api.php?url=https://xypt.wx.itxwzj.com/pages/index/index?sid=`+userInfo.sid+`&w=6`" class="rqcode-img" />
      <p class="p-qr">微信扫一扫查看门店</p>
    </van-popup>
    <Footer />
    <WxNoShare />
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useRouter } from "vue-router";
  import { useStore } from 'vuex'
  import { Grid, GridItem, Popup } from 'vant';

  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [Popup.name]: Popup,
    },
    setup() {
      //const { proxy } = getCurrentInstance();
      const store = useStore();
      const router = useRouter();
      const userInfo = ref(store.state);
      const rqCodeShow = ref(false);

      function onIcon() {
      }

      function showPopupQr() {
        rqCodeShow.value = true
      }

      function toUrl (name) {
        router.push({name: name})
      }

      return {
        userInfo,
        onIcon,
        rqCodeShow,
        showPopupQr,
        toUrl
      };
    },
  };
</script>
<style scoped>
  .manage{
    overflow: hidden;
  }
  .i-color >>> .van-grid-item__text{
    color:#b5b2b2;
  }
  .rqcode-img{
    margin: 10px;
  }
  .p-qr{
    text-align: center;
    margin: 0px 10px 10px;
    font-size: 14px;
  }
</style>
